import React from "react";
import Navbar2 from "../Navbar/Navbar2";
import Footer from "../Footer/Footer";
import { Box, Typography, Container } from "@mui/material";

const Whoare = () => {
  return (
    <Container maxWidth="xl" sx={{ flexGrow: 1 }}>
      <Navbar2 />
      <div style={{ direction: "rtl" ,paddingTop:'50px' , fontSize:'25px'}}>
      آثر المستقلون في هيئة التنسيق الوطنية لقوى التغيير الديمقراطي على البدئ بإصدار   مجلتهم بعنوان (مستقلون)..
      <br/>
    
      </div>
      <div style={{ direction: "rtl" ,paddingTop:'20px' , fontSize:'25px'}}>
         يعبرون فيها عن
آرائهم واهدافهم وأملهم، وعن البعد الوطني والقومي لقضيتهم التي هي قضيتنا جميعا كمواطنين سوريين مسالمين يبغون التغيير السلمي الى دولة مدنية ديمقراطية..

      </div>
      <div style={{ direction: "rtl" ,paddingTop:'20px' , fontSize:'25px'}}>
      إنّنا لسنا حزبيين.. ولا نأتمر بأمر حزب..  
      </div>
      <div style={{ direction: "rtl" ,paddingTop:'20px' , fontSize:'25px'}}>
      ولاعرقيين أعمى عيونهم التعصب..  ولا طائفيين عادوا الوطن والوطنية.. وإنما مستقلون وطنيذون  يحملون شمعة في نفق مظلم حفره لثورتنا  سلاح استعمل في غير مكانه وتسلط تجاوز كل معقول ..وفكر انفصالي وجد عدوا لسورية  يؤيده ويحميه وطائفية ومذهبية  اشعل نيرانها  ذوي الاحلام التسلطية أو التوسعية  .. عسى هذه الشمعة تضيء لنخرج جميعا من هذا النفق المرعب الى دولة مدنية ديمقراطية عادلة.
      </div>
      <h1 style={{direction:'rtl'}}>
        المستقلون
      </h1>
      <div style={{ direction: "rtl" ,paddingTop:'20px' , fontSize:'25px'}}>
      في هذه المجلة يعبر كل كاتب عن رأيه المستقل بلا قيود إلا قيود الاخلاق.. ولارقابة إلا رقابة الضمير.    
        </div>
        <div style={{ direction: "rtl" ,paddingTop:'20px' , fontSize:'25px'}}>
        نتوجه في هذه المجلة الى كل ثائر حر حافظ في مسيرته.. رغم المستنقعات الآسنة.. والانحرافات المشبوهة على طهارته الثورية وأهدافه العادلة.        </div>
        <div style={{ direction: "rtl" ,paddingTop:'20px' , fontSize:'25px'}}>
        تفتح مجلتنا صفحاتها الرحبة لكل قلم حر لكي يشاركنا الألم بما حلّ بالوطن.. والأمل بغدٍ مُشرقٍ واعد رغيد.لسنا حزبيين يأتمرون بأمر حزب..  ولا عرقيين أعمى التعصب أعينهم..  ولا طائفيين خرجوا عن الوطن وعليه أو لاوطنيين عادوا الوطنية والمواطنين.. بل نحن مستقلون محكومون بوطننا ووطنيتنا.        </div>
      <Footer />
    </Container>
  );
};

export default Whoare;
