import React from 'react'
import Navbar2 from "../../../Components/Home/Navbar/Navbar2";
const Archive = () => {
  return (
    <div>
      <Navbar2/>
    </div>
  )
}

export default Archive
