import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { useArticleStore } from "../Store";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import ArticleAddForm from "../../Components/Panel/AddForm/ArticleAddForm";
import ArticlesEditForm from "../../Components/Panel/EditForm/ArticlesEditForm";
import MediaAdd from "../../Components/Panel/AddForm/MediaAdd";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  right: "10%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height: 500,
  p: 2,
};

export default function Magazine() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [editopen, setEditOpen] = useState(false);

  const [formid, setFormid] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);

  const setRows = useArticleStore((state) => state.setRows);
  const rows = useArticleStore((state) => state.rows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getArticles = () => {
    axios
      .get("https://www.tanaghomtech.com/magazine/public/api/magazine")
      .then((response) => {
        console.log(response.data.data);
        const data = Array.isArray(response.data.data)
          ? response.data.data
          : [];
        setRows(data); // Assuming the data is an array of categories
        console.log("rows", rows);
        setOpen(false)
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  const performDelete = (id, accessToken) => {
    console.log(id);

    axios
      .delete(
        `https://www.tanaghomtech.com/magazine/public/api/magazine/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          Swal.fire("تم الحذف بنجاح", "", "success", {
            timer: 500,
          });
          getArticles();
        }
      })
      .catch((error) => {
        Swal.fire("خطأ", error.message, "error");
      });
  };
  const deleteCategory = (id) => {
    Swal.fire({
      title: "هل  أنت  متأكد؟ ",
      text: "لن  تتمكن  من التراجع  عن  هذا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#E1021F",
      confirmButtonText: "نعم،  احذفه",
    }).then((result) => {
      if (result.value) {
        const accessToken = localStorage.getItem("accessToken");
        performDelete(id, accessToken);
      }
    });
    getArticles()
  };

  const editData = (id, title, brief, content, image, category_id, country_id, writer_id, magazine_number) => {
    const data = {
      id: id,
      title: title,
      brief: brief,
      content: content,
      image: image,
      category_id: category_id,
      country_id: country_id,
      writer_id: writer_id,
      magazine_number: magazine_number
    };
    setFormid(data);
    handleEditOpen();
  };

  const filterData = (v) => {
    if (v) {
      setRows([v]);
    } else {
      setRows([]);

      getArticles();
    }
  };
  useEffect(() => {
    getArticles();
  }, []);
  const downloadPDF = async (filename) => {
    const baseURL = "https://www.tanaghomtech.com/magazine/storage/app/public/"; // Adjust your server path
    const pdfUrl = `${baseURL}${filename}`;
    await window.open(pdfUrl, "_blank"); // Opens in a new tab
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };


  return (
    <>
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <MediaAdd getArticles={getArticles} fid={formid} closeEvent={handleClose} />
          </Box>
        </Modal>
        <Modal
          open={editopen}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ArticlesEditForm closeEvent={handleEditClose} fid={formid} />
          </Box>
        </Modal>
      </div>
      {/* {rows.length > 0 && ( */}
      <Paper sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            width: "80vw"
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            magazine List
          </Typography>
        </Box>

        <Divider />
        <Box height={10} />
        <Stack
          direction="row"
          spacing={2}
          style={{ marginRight: "16px", marginLeft: "16px" }}
        >
          {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={rows}
              sx={{ width: 300 }}
              onChange={(e, v) => filterData(v)}
              getOptionLabel={(rows) => rows.title || ""}
              renderInput={(params) => (
                <TextField {...params} size="small" label="البحث عن المقالات" />
              )}
            /> */}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Button
            variant="contained"
            endIcon={<AddCircleIcon />}
            onClick={handleOpen}
          >
            اضافة
          </Button>
        </Stack>
        <Box height={10} />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "450px" }}>
            <TableHead   >
              <TableRow >

                <TableCell align="left" style={{ minWidth: "80px" }}>
                  magazine number
                </TableCell>
                {/* <TableCell align="left" style={{ minWidth: "80px" }}>
                    Link
                  </TableCell> */}
                <TableCell align="left" style={{ minWidth: "80px" }}>
                  pdf file
                </TableCell>
                <TableCell align="left" style={{ minWidth: "80px" }}>
                  electronic magazine
                </TableCell>
                <TableCell align="left" style={{ minWidth: "80px" }}>
                  magazine date
                </TableCell>
                <TableCell align="left" style={{ minWidth: "80px" }}>
                  action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows

                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} >

                      <TableCell align="left">
                        {row.magazine_number}
                      </TableCell>
                    
                      <TableCell align="left">
                      <Button  onClick={() => {
                        window.open(`https://www.tanaghomtech.com/magazine/storage/app/public/${row?.pdf_link}`, "_blank");
                      }} variant="outlined" href="#outlined-buttons">
                      عرض الملف
                      </Button>
                      </TableCell>

                      <TableCell align="left"><Button  onClick={() => {
                        window.open(`${row?.pdf_link}`, "_blank");
                      }} variant="outlined" href="#outlined-buttons">
                      عرض النسخة التفاعلية
                      </Button></TableCell>
                      <TableCell align="left">{row?.magazine_date}</TableCell>
                      <TableCell align="left">
                        <Stack spacing={2} direction="row">
                          {/* <EditIcon
                              style={{
                                fontSize: "20px",
                                color: "blue",
                                cursor: "pointer",
                              }}
                              className="cursor-pointer"
                              onClick={() => {
                                editData(row.id, row.title,row.brief, row.content,row.image,row.category_id,row.country_id,row.writer_id,row.magazine_number);
                              }}
                            /> */}
                          <DeleteIcon
                            style={{
                              fontSize: "20px",
                              color: "darkred",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              deleteCategory(row.id);
                            }}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* )} */}
      {/* {rows.length == 0 && (
        <>
          <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={30} />
            <Box height={40} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
          </Paper>
        </>
      )} */}
    </>
  );
}
