import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import pyramid from "../../../assets/pyramid.png";
import digol from "../../../assets/digol.png";
import mala from "../../../assets/mala.png";
import boat from "../../../assets/boat.png";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import SectionTitle from "../SectionTItle/SectionTitle";
import useFetch from "../../../hooks/useFetch";

const Religion = () => {
  const imgUrl ="https://www.tanaghomtech.com/magazine/storage/app/public/"
  const [newslist] = useFetch("https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=5&filter[status]=1&filter[archive]=0")
  if (!newslist || newslist.length < 5) return null;
  return (
    <>
      <SectionTitle sectionName="الدين والحياة" />

      <Box
        id="religion"
        sx={{
          flexGrow: 1,
          marginTop: "2rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* First card on the left side */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <HeroCard
                title={newslist[0]?.title}
                category={newslist[0]?.category?.categoryName}
                date={newslist[0]?.created_at}
                backgroundImage={imgUrl + newslist[0]?.imageLink}
                size="70vh"
                categoryColor="#FF5733"
                link={"/article/" + newslist[0]?.id}
              />
            </Box>
          </Grid>

          

          {/* Bottom card under the top two, spanning their combined width */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {newslist.slice(1).map((article)=>(
              <NewsCard
              title={article?.title}
              date={article?.created_at}
              imageUrl={imgUrl + article?.imageLink}
              link={"/article/" + article?.id}
            />
            ))}
          
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Religion;
