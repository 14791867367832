import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import Davinci from "../../../assets/Davinci.png";
import Painting from "../../../assets/painting.png";
import Writer from "../../../assets/Writer.png";
import Character from "../../../assets/Character.png";
import Writer2 from "../../../assets/writer2.png";
import City from "../../../assets/city1.png";
import SectionTitle from "../SectionTItle/SectionTitle";
import useFetch from "../../../hooks/useFetch";
const Education = () => {
  const imgUrl = "https://www.tanaghomtech.com/magazine/storage/app/public/"
  const [newslist] = useFetch("https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=3&filter[status]=1&filter[archive]=0")
  if (!newslist || newslist.length <6 ) return null;
  return (
    <>
      <SectionTitle sectionName="ثقافة" />
      <Box
        id="culture"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* Left side with one HeroCard and two NewsCards */}
          <Grid item xs={12} md={6}>
            <HeroCard
              title={newslist[0]?.title}
              category={newslist[0]?.category.categoryName || "ثقافة"} // Access categoryName if category is an object
              date={newslist[0]?.created_at}
              backgroundImage={imgUrl + newslist[0]?.imageLink}
              size="65vh"
              paragraph={newslist[0].paragraph}
              categoryColor="#FF5733"
              link={"/article/" + newslist[0]?.id}
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <NewsCard
                   title={newslist[2].title}
                   date={newslist[2].create_at}
                   imageUrl={imgUrl + newslist[2].image}
                   link={"/article/" + newslist[2]?.id}
                   
                />
              </Grid>
              <Grid item xs={12}>
                <NewsCard
                   title={newslist[3].title}
                   date={newslist[3].create_at}
                   imageUrl={imgUrl + newslist[3].image}
                   link={"/article/" + newslist[3]?.id}
                   
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right side with one HeroCard and two NewsCards */}
          <Grid item xs={12} md={6}>
          <HeroCard
              title={newslist[1]?.title}
              category={newslist[1]?.category.categoryName || "ثقافة"} // Access categoryName if category is an object
              date={newslist[1]?.created_at}
              backgroundImage={imgUrl + newslist[1]?.imageLink}
              size="65vh"
              paragraph={newslist[1].paragraph}
              categoryColor="#FF5733"
              link={"/article/" + newslist[1]?.id}
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <NewsCard
                   title={newslist[4].title}
                   date={newslist[4].create_at}
                   imageUrl={imgUrl + newslist[4].image}
                   link={"/article/" + newslist[4]?.id}
                   
                />{" "}
              </Grid>
              <Grid item xs={12}>
                <NewsCard
                  title={newslist[5].title}
                  date={newslist[5].create_at}
                  imageUrl={imgUrl + newslist[5].image}
                  link={"/article/" + newslist[5]?.id}
                  
                />{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Education;
