import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Modal,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import HeroCard from "../../Components/Home/HeroCard/HeroCard";
import axios from "axios";

const Settings = () => {
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedHero, setSelectedHero] = useState(null);
  const [hero, setHero] = useState(null);
  const [article, setArticle] = useState();
  // Fetch articles
  const getArticles = async () => {
    try {
      const response = await axios.get(
        "https://www.tanaghomtech.com/magazine/public/api/hero?filter[hero_number]=1,2,3,4"
      );
      setRows(Array.isArray(response.data.data) ? response.data.data : []);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };
  const editButton = async (hero_number) => {
    setOpen(true);
    setHero(hero_number);
    await console.log("hero_number", hero);
  };
  useEffect(() => {
    getArticles();
  }, []);
  const getArticlesFormodal = async () => {
    try {
      const response = await axios.get(
        "https://www.tanaghomtech.com/magazine/public/api/getarticles?fields[articles]=id,title"
      );
      setRows2(Array.isArray(response.data.data) ? response.data.data : []);
      console.log(response.data.data);
      console.log("article", article);
      getArticles();
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  useEffect(() => {
    getArticlesFormodal();
  }, [open, article, hero]);
  // Handles API update and opens modal
  const handleEdit = async (hero_id) => {
    try {
      const response = await axios.post(
        "https://www.tanaghomtech.com/magazine/public/api/hero",
        {
          article_id: hero_id,
          hero_number: hero,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Success:", response.data);
      console.log("hero", hero_id);

      // Store selected hero info for modal
      setSelectedHero(rows.find((hero) => hero.id === hero_id));
      setOpen(false); // Open modal
    } catch (error) {
      console.error("Error:", error.response ? error.response : error.message);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: "1rem",
        height: { sx: "auto", md: "100vh" },
        paddingRight: "200px",
        paddingLeft: "50px",
      }}
    >
      <Grid container spacing={1}>
        {/* First card */}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Box>
            <HeroCard
              title={rows[0]?.article_title}
              category={rows[0]?.article_category}
              date={new Date(rows[0]?.article_date).toLocaleDateString()}
              backgroundImage={`https://www.tanaghomtech.com/magazine/storage/app/public/${rows[0]?.article_image}`}
              size="91vh"
              categoryColor="#FF5733"
              link={`/article/${rows[0]?.article_id}`}
            />
            <Button
              variant="contained"
              style={{ marginTop: "-100px", marginLeft: "30px" }}
              onClick={() => editButton(1)}
            >
              Edit
            </Button>
          </Box>
        </Grid>

        {/* Other cards */}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Grid container spacing={1}>
            {rows.slice(1, 4).map((row, index) => (
              <Grid
                key={index}
                item
                xs={index === 2 ? 12 : 6}
                sm={index === 2 ? 12 : 6}
                md={index === 2 ? 12 : 6}
                lg={index === 2 ? 12 : 6}
                xl={index === 2 ? 12 : 6}
                style={{ position: "relative" }}
              >
                <HeroCard
                  title={row?.article_title}
                  category={row?.article_category}
                  date={new Date(row?.article_date).toLocaleDateString()}
                  backgroundImage={`https://www.tanaghomtech.com/magazine/storage/app/public/${row?.article_image}`}
                  size={index === 2 ? "45vh" : "45vh"}
                  categoryColor="#FF5733"
                  link={`/article/${row?.article_id}`}
                />

                <Button
                  variant="contained"
                  style={{
                    position: "absolute",
                    left: "20px",
                    bottom: "10px",
                    zIndex: 5,
                  }}
                  onClick={() => editButton(row?.hero_number)}
                >
                  Edit
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* Modal */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <h2>select article</h2>

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">article</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={article || ""}
                onChange={(e) => setArticle(e.target.value)} // Move onChange here
              >
                {rows2.map((pp) => (
                  <MenuItem key={pp.id} value={pp.id}>
                    {pp.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Button
            variant="contained"
            color="success"
            style={{ margin: "5px" }}
            onClick={() => handleEdit(article)}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            style={{ margin: "5px" }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Settings;
