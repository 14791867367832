import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";

const MediaAdd = ({ closeEvent ,getArticles }) => {
    const [description, setDescription] = useState("");
    const [number, setNumber] = useState("");
    const [url, setUrl] = useState(""); // For storing YouTube URL
    const [file, setFile] = useState(null); // For storing file
    const [link, setLink] = useState(""); // The selected type (youtube, image, video)
    const [datenumber, setDatenumber] = useState([]); // Category options
    const [category, setCategory] = useState(""); // The selected category

 
    // File change handler
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0]; // Get the first file from the input
        if (selectedFile) {
            setFile(selectedFile); // Store the file in the state
        }
    };

    // Fetch categories from API
    
    // Handle form submission
   
    
    const createCategory = async () => {
        console.log("hi");
    
        if (!number || !file || !datenumber) {
            alert("يرجى ملء جميع الحقول المطلوبة!");
            return;
        }
    
        // Ensure date is in proper format (YYYY-MM-DD)
        const formattedDate = new Date(datenumber).toISOString().split("T")[0];
    
        const token = localStorage.getItem("accessToken");
        const formdata = new FormData();
        formdata.append("magazine_number", number);
        formdata.append("pdf_link", file);
        formdata.append("link", link);
        formdata.append("magazine_date", formattedDate);
    
        try {
            const response = await fetch("https://www.tanaghomtech.com/magazine/public/api/magazine", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formdata, // No need to set 'Content-Type' manually for FormData
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            console.log("Success:", data);
    
            getArticles();
            closeEvent();
        } catch (error) {
            console.error("Error occurred while making request:", error);
        }
    };
    
    return (
        <div style={{ width: "100%" }}>
            <Box sx={{ m: 2 }} />
            <Typography variant="h5" align="center">
                اضافة عدد جديد
            </Typography>
            <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Box height={10} />
            <Grid container spacing={2}>
               

                {/* Category Selection */}
                <Grid item xs={12}>
                        <InputLabel id="category-select-label">العدد</InputLabel>
                        <TextField
                        id="outlined-basic"
                        label="number"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setNumber(e.target.value)}
                        value={number}
                        sx={{ minWidth: "100%" }}
                    />       
                </Grid>

                {/* Type Selection */}
              
                <Grid item xs={12}>
                <InputLabel id="type-select-label">pdf الملف الالكنروني للعدد</InputLabel>
                        <input
                           type="file"
                            accept="application/pdf"
                            onChange={handleFileChange}
                            style={{ width: "100%" }}
                        />
                        {/* You can also show a preview or file name if you want */}
                        {file && <Typography variant="body2">Selected file: {file.name}</Typography>}
                    </Grid>
                {/* Description Field */}
                <Grid item xs={12}>
                <InputLabel id="type-select-label">رابط العدد</InputLabel>
                    <TextField
                        id="outlined-basic"
                        label="Description"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setLink(e.target.value)}
                        value={link}
                        sx={{ minWidth: "100%" }}
                    />
                </Grid>

                <Grid item xs={12}>
                <InputLabel id="type-select-label">تاريخ العدد</InputLabel>
                    <TextField
                        id="outlined-basic"
                        label="Title"
                        type="date"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setDatenumber(e.target.value)}
                        value={datenumber}
                        sx={{ minWidth: "100%" }}
                    />
                </Grid>

              

                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        <Button  style={{backgroundColor:"#7886C7"}}  variant="contained" onClick={createCategory}>
                            اضافة
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default MediaAdd;
