import React from 'react'
import Navbar2 from '../Navbar/Navbar2'
import { Grid } from '@mui/material'
import Sidenav from '../../Panel/Sidenav/Sidenav'
import Magazine from '../../../Pages/MagazinePage/Magazine'
import Footer from "../Footer/Footer";
import Magazinemostaqlon from '../../../Pages/MagazinePage/Magazinemostaqlon'
const Mostaqlon = () => {
  return (
    <div>
      <Navbar2/>
      <Grid container spacing={2} style={{marginLeft:"-230px",marginTop:"50px"}}>
 
    <Grid sx={{ display: "flex" }} xs={3}>
      </Grid>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
      <Grid  xs={9} component="main" sx={{ display: "flex", justifyContent: "center", alignItems: "center",p:3 }}>
      <Magazinemostaqlon/>
      </Grid>
 
  </Grid>
  <Footer/>
    </div>
  )
}

export default Mostaqlon
