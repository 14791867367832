import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box, Typography, Container } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Navbar1 from "../../Components/Home/Navbar/Navbar1";
import Navbar2 from "../../Components/Home/Navbar/Navbar2";
import Topbar from "../../Components/Home/TopBar/Topbar";
import Footer from "../../Components/Home/Footer/Footer";
import Stack from "@mui/material/Stack";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import IconButton from "@mui/material/IconButton";
import img1 from "../../assets/data1.png";
import NewsCard from "../../Components/Home/NewsCard/NewsCard";

import parse from "html-react-parser";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const RootContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(1),
}));

const FullWidthImage = styled("img")({
  width: "100%",
  height: "80vh",
});

const Content = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: "white",
}));

const Article = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  // const {showauthor }=useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [newsData, setNewsData] = useState(null);
  const [articlesData, setArticlesData] = useState([]);
  const [articleid, setArticleid] = useState();
  const handleResultClick = (id) => {
    setArticleid(id);
    navigate(`/article/${id}`);
  };

  useEffect(() => {
    const getNew = () => {
      const queryParams = new URLSearchParams(location.search);
      const idFromQuery = queryParams.get("id") || id;

      axios
        .get(
          `https://www.tanaghomtech.com/magazine/public/api/article/${idFromQuery}?include=category,writer,country,sections`
        )
        .then((response) => {
          setNewsData(response?.data?.data);
          console.log(response?.data?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
          setIsLoading(false);
        });
    };

    getNew();
  }, [articleid]);

  useEffect(() => {
    const getNews = () => {
      const queryParams = new URLSearchParams(location.search);
      const idFromQuery = queryParams.get("id") || id;
      axios
        .get(
          `https://www.tanaghomtech.com/magazine/public/api/getarticlesbutnoteselected/${idFromQuery}?include=category,writer,country,sections`
        )
        .then((response) => {
          setArticlesData(response?.data.data);
          console.log(response?.data.data);
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    };

    getNews();
  }, [articleid]);

  if (isLoading) {
    return (
      <CircularProgress
        sx={{ position: "absolute", left: "50%", top: "50%", color: "red" }}
        disableShrink
      />
    );
  }
  if (!newsData) {
    return <div>Loading...</div>; // Fallback UI while data is null
  }

  const { title, author, created_at, content, imageLink, showauthor } =
    newsData;
  const formattedDate = new Date(created_at).toLocaleTimeString("default", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    time: "numeric",
  });

  return (
    <>
      <Container maxWidth="xl" sx={{ flexGrow: 1 }}>
        {/* <Navbar1 />
        <Topbar /> */}

        <Navbar2 />

        <Grid container spacing={2}>
          <Grid xs={12} md={8}>
            <Stack spacing={2}>
              <Item
                sx={{
                  fontSize: "32px",
                  fontWeight: 800,
                  textAlign: "right",
                  color: "#000",
                  boxShadow: "none",
                  paddingTop: "50px",
                }}
              >
                {newsData?.title}
              </Item>
              <Item
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  textAlign: "right",
                  color: "#000",
                  boxShadow: "none",
                }}
              >
                {formattedDate} | {newsData?.writer?.writerName} |{" "}
                {newsData?.category?.categoryName} |{" "}
                {newsData?.country?.countryName}
              </Item>
              <Item
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  textAlign: "right",
                  color: "#000",
                  boxShadow: "none",
                }}
              >
                <IconButton
                  sx={{
                    padding: "10px",
                    borderRadius: "50%",
                    backgroundColor: "red",
                    color: "white",
                    margin: "0 10px",
                    ":hover": {
                      bgcolor: "red", // theme.palette.primary.main
                      color: "white",
                    },
                  }}
                  color="primary"
                  aria-label="add to shopping cart"
                >
                  <YouTubeIcon />
                </IconButton>

                <IconButton
                  sx={{
                    padding: "10px",
                    borderRadius: "50%",
                    backgroundColor: "green",
                    color: "white",
                    margin: "0 10px",
                    ":hover": {
                      bgcolor: "green", // theme.palette.primary.main
                      color: "white",
                    },
                  }}
                >
                  {" "}
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  sx={{
                    padding: "10px",
                    borderRadius: "50%",
                    backgroundColor: "black",
                    color: "white",
                    margin: "0 10px",
                    ":hover": {
                      bgcolor: "black", // theme.palette.primary.main
                      color: "white",
                    },
                  }}
                >
                  <FacebookIcon />
                </IconButton>
              </Item>

              <Item
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  textAlign: "right",
                  color: "#000",
                  boxShadow: "none",
                }}
              >
                <img
                  src={`https://www.tanaghomtech.com/magazine/storage/app/public/${newsData.imageLink}`}
                  alt="User"
                  style={{ width: "100%", maxHeight: "600px" }}
                />
              </Item>

              <Item
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  textAlign: "right",
                  color: "#000",
                  boxShadow: "none",
                  direction: "rtl",
                }}
              >
                {newsData.sections.map((item) =>
                  item.type == "text" ? (
                    parse(item.content)
                  ) : (
                    <img
                      src={`https://www.tanaghomtech.com/magazine/storage/app/public/${item.content}`}
                      alt="User"
                      style={{ width: "100%", maxHeight: "600px" }}
                    />
                  )
                )}
                {/* {parse(newsData.content)} */}
              </Item>
            </Stack>
          </Grid>
          <Grid xs={12} md={4}>
            <Item
              sx={{
                fontSize: "32px",
                fontWeight: 800,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
                paddingTop: "50px",
              }}
            >
              المقالات الأخيرة
            </Item>

            {articlesData?.map((article) => (
              <a onClick={() => handleResultClick(article.id)}>
                <NewsCard
                  title={article?.title}
                  writer={
                    article?.writer?.writerName +
                    " | " +
                    article?.category?.categoryName +
                    " | " +
                    article?.country?.countryName
                  }
                  imageUrl={
                    "https://www.tanaghomtech.com/magazine/storage/app/public/" +
                    article.imageLink
                  }
                  // link={`/article/${article.id}`}
                  date={new Date(article.created_at).toLocaleTimeString(
                    "default",
                    {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      time: "numeric",
                    }
                  )}
                />
              </a>
            ))}

            {/* {
              articlesData.map(function(article, i) {
                return (i < 10) ? <NewsCard
                title={article.title}
                writer={article.writer.writerName + " | "+  article.category.categoryName + " | "+  article.country.countryName }
                imageUrl={"https://www.tanaghomtech.com/magazine/storage/app/public/" + article.imageLink}
                link={"/article/" + article.id}
                date={new Date(article.created_at).toLocaleTimeString("default", {
                  year: "numeric", month: "numeric", day: "numeric", time: "numeric"
                })}
              /> : null 
              }).filter(x=>x)
            } */}
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </>
  );
};

export default Article;
