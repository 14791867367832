import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardMedia, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { InfoRounded } from '@mui/icons-material';
import Topbar from '../Components/Home/TopBar/Topbar';
import Navbar1 from '../Components/Home/Navbar/Navbar1';
import Navbar2 from '../Components/Home/Navbar/Navbar2';
import { Link, useLocation, useParams } from 'react-router-dom';
import Footer from "../Components/Home/Footer/Footer";
import moment from 'moment'
import { use } from 'react';
import axios from 'axios';
const Details = () => {
    const [data1 ,setData1]= useState([])
   const {id} =useParams()
   console.log(id);
    const imgUrl = "https://www.tanaghomtech.com/magazine/storage/app/public/"
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[status]=1&filter[archive]=1&filter[magazine_number]=${id}`);
            setData1(response.data.data);
             // Assuming you want to set the data part of the response
          } catch (error) {
            console.log(error);
          }
        };
      
        fetchData();
      }, [id]); // Add `id` as a dependency if it can change
  return (
    <div style={{ margin: '20px' }}>
      {/* <Navbar1 />
      <Topbar /> */}
      <Navbar2 />
       <Grid container style={{direction:"rtl"}} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {data1?.map((article) => (
              <Grid  item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Link style={{textDecoration:"none"}} to={`/article/${article.id}`}>
              <Card
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: 'flex',
                    zIndex: 1,
                    margin: '20px 40px',
                    height: "150px",
                    width: "400px",
                    direction: "rtl"
                  }}
                >
                  <CardMedia
                    component="img"

                    alt={article.title}
                    src={imgUrl + article.imageLink}
                    sx={{
                      borderRadius: '6px',
                      width: "150px",
                    }}
                  />
                  <Box sx={{ alignSelf: 'center', ml: 2 }}>


                    <Typography
                      fontWeight="bold"
                      gutterBottom
                      sx={{
                        wordWrap: 'break-word', // Ensures text wraps properly
                        overflow: article.title.length > 25 ? 'visible' : 'hidden',
                        whiteSpace: article.title.length > 25 ? 'normal' : 'nowrap',
                        padding: "10px"
                      }}
                    >
                      {article.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight="regular"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        padding: "10px"
                      }}
                    >
                      {article.writer.writerName}
                    </Typography>

                    <Typography
                      sx={{
                        padding: "10px",
                        fontSize: "12px"
                      }}
                      variant="body2"
                      color="text.secondary"
                      fontWeight="regular"
                    >
                      {moment(article.created_at).format("MMM Do YY")}
                    </Typography>
                  </Box>

                </Card>
              </Link>
              </Grid>
            ))}
          </Grid>
      <Footer/>
    </div>
  );
};

export default Details;
