import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import HeroCard from "../HeroCard/HeroCard";
import useFetch from "../../../hooks/useFetch";
import SectionTitle from "../SectionTItle/SectionTitle";

const Openion = () => {
  const imgUrl = "https://www.tanaghomtech.com/magazine/storage/app/public/";
  const [newslist] = useFetch("https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=6&filter[status]=1&filter[archive]=0");

  // Don't render anything if there are no posts
if (!newslist || newslist.length < 5) return null;

  return (

    <Box
      id="opinion"
      sx={{
        flexGrow: 1,
        marginTop: "1rem",
        height: { sx: "auto", md: "100vh" },
      }}
    >
      <SectionTitle sectionName="رأي" />
      <Grid container spacing={1}>
        {/* Dynamically render HeroCards using newslist */}
        {newslist?.map((article, index) => (
          <Grid key={index} item xs={12} md={4}>
            <HeroCard
              title={article?.title}
              category={article.category?.categoryName || "رأي"}// Fallback to "رأي" if categoryName is missing
              date={article?.created_at}
              backgroundImage={imgUrl + article?.imageLink}
              size="45vh"
              paragraph={article?.brief}
              categoryColor="#FF5733"
              link={"/article/" + article?.id}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Openion;

